@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.App {
  background-color: transparent;
  text-align: center;

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .form-label {
    width: 100%;
    color: $bpk-text-secondary-day;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;

    @include bpk-label-2;

    @include bpk-rtl {
      text-align: right;
    }

    @include bpk-breakpoint-mobile {
      display: none;
    }
  }

  .location-label {
    margin-top: bpk-spacing-sm();
  }

  .suggestions-container {
    margin-top: $bpk-one-pixel-rem * -4;
  }

  .form-label--pseudo-disabled {
    color: $bpk-text-disabled-day;

    @include bpk-breakpoint-mobile {
      display: none;
    }
  }

  .form {
    @include bpk-breakpoint-above-tablet {
      display: table-row;
      width: 100%;
    }

    @include bpk-rtl {
      text-align: right;
    }
  }

  .flights-sc-wrapper {
    position: relative; /* only as container */
    display: block;
    margin: 0 auto;
    text-align: left;

    @include bpk-rtl {
      text-align: right;
    }
  }

  .set-width {
    padding: bpk-spacing-md();

    @include bpk-breakpoint-above-mobile {
      max-width: 1048px; /* stylelint-disable-line unit-disallowed-list */
      padding: 0;
    }
  }

  .submit-button {
    height: 4.5rem; // to align with height of search controls
    border-radius: $bpk-border-radius-md;

    @include bpk-breakpoint-above-mobile {
      margin-bottom: 3rem; // to align with bottom row of search controls
      align-self: flex-end;
    }
  }

  .submit-button-oneline {
    @include bpk-breakpoint-above-tablet {
      bottom: bpk-spacing-sm();
      align-self: flex-start;
    }
  }

  &__tick,
  &__arrow {
    fill: $bpk-canvas-day;
  }

  &__text {
    display: inline-block;
    margin-right: bpk-spacing-xxl();

    @include bpk-rtl {
      margin-right: 0;
      margin-left: bpk-spacing-xxl();
    }
  }

  &__main {
    padding: bpk-spacing-lg() 0;
  }

  &__toggles {
    float: right;
    direction: ltr;
  }
}

.flights-sc-checkbox {
  margin-top: bpk-spacing-md();
  margin-right: bpk-spacing-md();
  margin-bottom: bpk-spacing-md();
  cursor: pointer;

  @include bpk-rtl {
    margin-right: 0;
  }
}

.flights-sc-hide-checkbox {
  height: bpk-spacing-xxl();
}

.flights-sc-checkbox-oneline {
  @include bpk-breakpoint-above-tablet {
    margin-bottom: 0;
  }
}

/**
 * this is a slightly ugly hack to make sure the flightsSearchControls div is big
 * enough to accommodate the SubmitButton that needs to be anchored in the corner.
 */
.flights-sc-directonly {
  @include bpk-breakpoint-tablet {
    height: bpk-spacing-xl();
  }
}

.flights-sc-directonly-multiline {
  height: bpk-spacing-xl();
}

.flextickets-sc-checkbox {
  margin: bpk-spacing-md();
  cursor: pointer;
}

.flextickets-sc-checkbox-oneline {
  @include bpk-breakpoint-tablet {
    display: block;
    height: bpk-spacing-xl();
    margin: 0;
  }
}

.fsc-large-above-tablet {
  @include bpk-breakpoint-above-tablet {
    @include bpk-input--large;
  }
}

.fsc-docked-middle {
  @include bpk-input--docked-middle-child;
}

.fsc-docked-middle-above-tablet {
  @include bpk-breakpoint-above-tablet {
    @include bpk-input--docked-middle-child;
  }
}

.fsc-select {
  border-bottom-right-radius: $bpk-border-radius-md;
  border-color: transparent;
  background-color: $bpk-canvas-day;
  color: $bpk-text-primary-day;
}

.fsc-select-docked-last {
  @include bpk-select--docked-last-child;
}

.fsc-form-validation {
  margin: 0 0 bpk-spacing-md() 0;
  padding: bpk-spacing-sm() bpk-spacing-md();
  background-color: $bpk-status-danger-spot-day;
  color: $bpk-canvas-day;

  @include bpk-border-radius-xs;
}

.controls-wrapper {
  display: flex;
  justify-content: space-between;
}
