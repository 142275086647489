@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.TripTypeSelector {
  margin-right: bpk-spacing-base();
  margin-bottom: bpk-spacing-md();
  color: $bpk-canvas-day;
  font-size: $bpk-font-size-sm;
  cursor: pointer;

  @include bpk-breakpoint-above-tablet {
    font-size: $bpk-font-size-base;
  }

  @include bpk-rtl {
    margin-right: 0;
    margin-left: bpk-spacing-base();
  }
}
