@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.modal-autosuggest-input-aria-label {
  display: none;
}

.AutosuggestModal {
  transition: none;
}

.modal-autosuggest {
  display: flex;
  align-items: center;
  border-color: transparent;
  background-color: $bpk-canvas-day;
  color: $bpk-text-primary-day;
  text-align: left;
  overflow: hidden;
  padding-inline-start: 0;

  @include bpk-rtl {
    text-align: right;
  }

  svg {
    fill: $bpk-text-secondary-day;
    margin-inline-end: bpk-spacing-md();
  }

  &__modal-button--empty {
    color: $bpk-text-secondary-day;
  }

  &__textfield-container {
    position: relative;
  }

  &__textfield {
    width: 100%;
    height: 3rem;
    padding: 1rem;
    padding-right: 2rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: 0.2rem;
    border-color: $bpk-surface-highlight-day;
    appearance: none;

    &::placeholder {
      color: $bpk-text-secondary-day;
    }

    &-clear-button-area {
      position: absolute;
      right: 0.2rem;
      width: 2rem;
      height: 100%;
    }

    &-clear-button {
      position: absolute;
      top: calc(50% - 0.5625rem);
      right: calc(50% - 0.5625rem);
      fill: $bpk-canvas-contrast-day;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    background-color: $bpk-canvas-day;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__suggestions-container {
    position: absolute;
    top: 7.5rem;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__suggestion-item {
    cursor: pointer;

    @include bpk-text;
    @include bpk-text--base;
    @include bpk-border-bottom-sm($bpk-surface-highlight-day);

    &:active {
      background-color: $bpk-canvas-contrast-day;
    }

    &--highlighted {
      background-color: $bpk-canvas-contrast-day;
    }
  }

  &__suggestion {
    display: table;
    width: 100%;
    padding: bpk-spacing-md() bpk-spacing-base();

    &--indent {
      padding-left: bpk-spacing-xl();
    }

    &-icon {
      display: table-cell;
      margin-top: 0.1875rem;
      margin-right: bpk-spacing-sm();
      vertical-align: top;
      fill: $bpk-text-secondary-day;

      @include bpk-rtl {
        margin-right: 0;
        margin-left: bpk-spacing-sm();
      }
    }

    &-inner {
      display: table-cell;
      width: 100%;
      vertical-align: top;
    }

    &-value {
      display: block;
      width: 100%;
    }

    &-sub-heading {
      display: table-cell;
      width: 100%;
      vertical-align: top;

      @include bpk-text;
      @include bpk-text--sm;
    }

    &-tertiary-label {
      display: table-cell;
      vertical-align: top;

      @include bpk-text;
      @include bpk-text--sm;
    }

    &--everywhere {
      color: $bpk-core-accent-day;

      svg {
        fill: $bpk-core-accent-day;
      }
    }
  }

  &__suggestion-recommend-destinations {
    // In Chrome, suggestions do not fill all the available space and a white line is shown in the right
    width: calc(100% + 0.1rem);
    padding: bpk-spacing-md();

    @include bpk-rtl {
      text-align: right;
    }
  }
}
