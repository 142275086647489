@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.RangeStateButton {
  padding: bpk-spacing-sm() bpk-spacing-sm();
  flex: 1 0 0;
  border-width: 0;
  background-color: transparent;
  text-align: left;

  &:last-child {
    text-align: right;
    box-shadow: -1px 0 0 0 $bpk-surface-highlight-day; /* stylelint-disable-line unit-disallowed-list */
  }

  &__label {
    display: block;
    color: $bpk-text-secondary-day;

    &--active {
      color: $bpk-font-color-base;
    }
  }

  &__date {
    color: $bpk-text-secondary-day;

    &--active {
      color: $bpk-font-color-base;
    }
  }
}
