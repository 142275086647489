@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.fsc-autosuggest {
  &__suggestions-container {
    &--open {
      @include bpk-breakpoint-above-tablet {
        // 40% wider is agreed upon as a good value for wider autosuggest
        width: 180%;
        // stylelint-disable property-no-vendor-prefix
        -webkit-transform: translateZ(0);

        @include bpk-rtl {
          &::before {
            left: 72%;
          }

          &::after {
            left: 72%;
          }
        }

        &::before {
          left: 28%;
        }

        &::after {
          left: 28%;
        }
      }
    }
  }
}

.LocationSelector {
  padding: bpk-spacing-md() bpk-spacing-base();
  background-color: $bpk-canvas-day;

  @include bpk-breakpoint-mobile {
    display: flex;
    min-height: 3rem;
    flex-direction: column;
    justify-content: center;
  }

  &--origin {
    border-radius: $bpk-border-radius-md 0 0 $bpk-border-radius-md;

    @include bpk-rtl {
      border-radius: 0 $bpk-border-radius-md $bpk-border-radius-md 0;
    }

    @include bpk-breakpoint-mobile {
      border-radius: $bpk-border-radius-md;

      @include bpk-rtl {
        border-radius: $bpk-border-radius-md;
      }
    }
  }

  &--destination {
    border-radius: 0 $bpk-border-radius-md $bpk-border-radius-md 0;
    margin-inline: bpk-spacing-sm();
    padding-inline-start: bpk-spacing-lg();

    @include bpk-rtl {
      border-radius: $bpk-border-radius-md 0 0 $bpk-border-radius-md;
    }

    @include bpk-breakpoint-mobile {
      border-radius: $bpk-border-radius-md;
      margin-inline: 0;
      padding-inline-start: bpk-spacing-base();

      @include bpk-rtl {
        border-radius: $bpk-border-radius-md;
      }
    }

    &-one-line {
      @include bpk-breakpoint-above-tablet {
        border-radius: 0;

        @include bpk-rtl {
          border-radius: 0;
        }
      }
    }
  }

  &--multi-city {
    border-radius: $bpk-border-radius-md;

    @include bpk-breakpoint-mobile {
      padding: bpk-spacing-md();
    }
  }

  .fsc-location-input {
    padding: 0;
    border: transparent;

    @include bpk-rtl {
      text-align: right;
    }

    @include bpk-breakpoint-above-tablet {
      text-overflow: ellipsis;
    }

    &:focus {
      outline: none;
    }
  }

  .fsc-suggestions-list {
    width: auto;
    border: none;
  }
}

.fsc-suggestion {
  // In Chrome, suggestions do not fill all the available space and a white line is shown in the right
  width: calc(100% + 0.1rem);

  svg {
    fill: $bpk-text-secondary-day;
  }
}

.fsc-suggestion-everywhere {
  // In Chrome, suggestions do not fill all the available space and a white line is shown in the right
  width: calc(100% + 0.1rem);
  border-color: $bpk-core-accent-day;
  background-color: $bpk-core-accent-day;
  color: $bpk-canvas-day;

  @include bpk-rtl {
    text-align: right;
  }

  svg {
    fill: $bpk-canvas-day;
  }
}

.fsc-suggestion-highlighted {
  .fsc-suggestion {
    background-color: $bpk-canvas-contrast-day;
  }

  .fsc-suggestion-everywhere {
    background-color: $bpk-core-accent-day;
  }
}

.fsc-suggestion-recommend-destinations {
  // In Chrome, suggestions do not fill all the available space and a white line is shown in the right
  width: calc(100% + 0.1rem);
  padding: bpk-spacing-base();

  @include bpk-rtl {
    text-align: right;
  }
}

.fsc-suggestion-recommend-destinations-spacing {
  margin-bottom: bpk-spacing-md();
}

.fsc-suggestion-recommend-destinations-chip {
  margin-top: bpk-spacing-md();
  margin-right: bpk-spacing-md();
  border-radius: $bpk-border-radius-xs;
}
