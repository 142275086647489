@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.DateRangeSelector {
  display: flex;
  min-height: 3rem;

  .form-item {
    display: flex;
    padding: 0 bpk-spacing-base();
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    background-color: $bpk-canvas-day;

    &:first-of-type {
      border-radius: $bpk-border-radius-md 0 0 $bpk-border-radius-md;

      @include bpk-rtl {
        border-radius: 0 $bpk-border-radius-md $bpk-border-radius-md 0;
      }
    }

    &:last-of-type {
      margin-inline-start: bpk-spacing-sm();

      @include bpk-breakpoint-mobile {
        border-radius: 0 $bpk-border-radius-md $bpk-border-radius-md 0;

        @include bpk-rtl {
          border-radius: $bpk-border-radius-md 0 0 $bpk-border-radius-md;
        }
      }
    }
  }

  .form-item-oneline {
    &:first-of-type {
      @include bpk-breakpoint-above-tablet {
        border-radius: 0;

        @include bpk-rtl {
          border-radius: 0;
        }
      }
    }
  }
}
