@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.DateInput {
  display: grid;
  width: 100%;
  margin-top: $bpk-one-pixel-rem * 2;
  padding: 0;
  border-color: transparent;
  background: $bpk-canvas-day;
  color: $bpk-font-color-base;
  text-align: left;
  cursor: pointer;

  @include bpk-rtl {
    text-align: right;
  }

  span {
    align-items: center;
  }

  svg {
    fill: $bpk-text-secondary-day;
    margin-inline-end: bpk-spacing-md();

    @include bpk-breakpoint-above-mobile {
      display: none;
    }
  }

  &--text {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--placeholder {
    color: $bpk-text-secondary-day;
  }

  &--disabled {
    color: $bpk-text-disabled-day;
  }

  &--docked {
    @include bpk-breakpoint-above-tablet {
      border-right-width: 0;
      border-radius: 0;
    }
  }

  &--multi-city {
    display: flex;
    height: 3.25rem;
    margin-top: 0;
    padding: bpk-spacing-base();
    flex-direction: column;
    justify-content: center;
    border-radius: $bpk-border-radius-md;

    @include bpk-breakpoint-mobile {
      height: 3rem;
      padding: bpk-spacing-md();
    }
  }
}
