@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.MonthSelector {
  text-align: left;

  &__month {
    width: calc(50% - 2 * #{bpk-spacing-sm()});
    margin: bpk-spacing-sm();
    padding: bpk-spacing-sm() bpk-spacing-md();
    border: 0;
    background-color: $bpk-canvas-day;
    color: $bpk-core-accent-day;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;

    @include bpk-border-radius-xs;
    @include bpk-border-sm($bpk-surface-highlight-day);
  }

  &__month:hover {
    color: $bpk-core-accent-day;

    @include bpk-border-sm($bpk-core-accent-day, inset);
  }

  &__month:active {
    color: $bpk-core-accent-day;

    @include bpk-border-lg($bpk-core-accent-day, inset);
  }

  &__month--selected:not(:hover, :active) {
    background-color: $bpk-core-accent-day;
    color: $bpk-canvas-day;

    @include bpk-border-lg($bpk-core-accent-day, inset);
  }

  &__year {
    display: block;
    width: 100%;

    @include bpk-text-sm;
  }

  &__title {
    width: 100%;
    text-align: center;

    @include bpk-text-base;
  }
}
