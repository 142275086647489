@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

$line-height: normal;

.MulticityControls {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__legs {
    margin: 0;
    padding: 0;
    line-height: $line-height;
    list-style-type: none;
  }

  &__leg-wrapper {
    display: flex;
    margin-bottom: bpk-spacing-base();
    align-items: center;
  }

  &__leg-location {
    position: relative;
    float: left;
    width: 34%;

    @include bpk-breakpoint-mobile {
      width: 20%;
      margin: 0;

      svg {
        display: none;
      }
    }
  }

  &__leg-location-triangle {
    text-align: center;

    @include bpk-rtl {
      transform: scaleX(-1);
    }

    svg {
      fill: $bpk-canvas-day;
    }
  }

  &__leg-date {
    position: relative;
    float: left;
    width: 23%;
    margin-inline-start: bpk-spacing-md();

    @include bpk-breakpoint-mobile {
      width: 38%;

      svg {
        display: none;
      }
    }
  }

  &__leg-remove-wrapper {
    float: left;
    width: 5%;
    padding: 0;
    text-align: center;
  }

  &__leg-remove-btn {
    padding: 0.375rem 1.125rem;
    border: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      transition: fill-opacity $bpk-duration-sm ease-in-out;
      fill: $bpk-canvas-day;
    }

    &:hover,
    &:focus {
      svg {
        fill-opacity: 0.25;
      }
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill-opacity: 0.25;
      }
    }
  }

  &__add-leg-wrapper {
    button {
      display: block;
      margin-bottom: 2.25rem;
    }
  }

  &__add-leg-button {
    border-radius: $bpk-border-radius-md;
  }

  &__add-leg-icon {
    fill: $bpk-core-accent-day;
  }

  &__cabin-class-travellers-wrapper {
    @include bpk-breakpoint-mobile {
      width: 100%;
      margin-bottom: 0.75rem;
    }

    @include bpk-breakpoint-above-mobile {
      width: 35%;
    }
  }

  &__bottom-container {
    display: flex;

    @include bpk-breakpoint-mobile {
      flex-direction: column;
    }

    @include bpk-breakpoint-above-mobile {
      justify-content: space-between;
    }
  }

  .submit {
    width: fit-content;
    height: 3.5rem;
    border-radius: $bpk-border-radius-md;

    @include bpk-breakpoint-mobile {
      width: 100%;
    }
  }
}
