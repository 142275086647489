@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.fsc-datepicker {
  &__container {
    padding: bpk-spacing-md();
    text-align: center;
  }

  &__monthselector-container {
    width: 100%;
    padding: bpk-spacing-md();
    overflow: auto;
  }

  &__monthselector {
    max-width: 7 * ($bpk-calendar-day-size + $bpk-calendar-day-spacing);
    margin: 0 auto;
  }

  &__list-size {
    display: flex;
    min-height: 0;
    flex-direction: column;
    flex-grow: 1;
  }

  &__tab-bar {
    flex-shrink: 0;
    white-space: nowrap;
  }

  &__calendar {
    display: flex;
    margin: auto;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__cell {
    padding: bpk-spacing-sm() 0 0 0;
  }

  &__datebg {
    width: 100%;
  }

  &__datebg--start {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 50%,
      $bpk-canvas-contrast-day 50%,
      $bpk-canvas-contrast-day
    );
  }

  &__datebg--in-range {
    background-color: $bpk-canvas-contrast-day;
  }

  &__datebg--end {
    background-image: linear-gradient(
      to right,
      $bpk-canvas-contrast-day,
      $bpk-canvas-contrast-day 50%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0)
    );
  }

  &__date-buttons {
    display: flex;
    margin-top: bpk-spacing-base();
    margin-bottom: bpk-spacing-md();
    padding: 0 bpk-spacing-base();
    justify-content: space-between;
    flex-shrink: 0;
  }

  &__done-button {
    margin: bpk-spacing-base();
    flex-shrink: 0;
  }
}

.fsc-datepicker__modal {
  display: flex;
  transition: none;
}

.fsc-datepicker__modal-content {
  display: flex;
  flex-direction: column;
}

.fsc-datepicker__calendar-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.fsc-grid-list {
  h1 {
    margin-inline-start: bpk-spacing-base();
  }
}
