@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.SingleDestControls {
  display: grid;
  width: 100%;
  grid-template:
    'origin origin origin destination destination destination' 1fr
    'alt-origin alt-origin alt-origin  alt-dest alt-dest alt-dest' 0.2fr
    'dates dates dates traveller traveller search' 1fr
    'direct-flights direct-flights direct-flights . . .' 0.2fr;

  @include bpk-breakpoint-mobile {
    display: grid;
    grid-template:
      'origin' 0.8fr
      'alt-origin' 0.2fr
      'destination' 0.8fr
      'alt-dest' 0.2fr
      'dates' 0.8fr
      'traveller' 0.8fr
      'direct-flights' 0.2fr
      'search' 1fr;
    row-gap: bpk-spacing-md();
  }

  .form-label {
    color: $bpk-canvas-day;
  }

  .form {
    @include bpk-breakpoint-above-tablet {
      display: table-row;
      width: 100%;
    }
  }

  .origin-wrapper {
    grid-area: origin;
  }

  .destination-wrapper {
    position: relative;
    grid-area: destination;
  }

  .dates-wrapper {
    display: grid;
    grid-area: dates;
    margin-inline-end: bpk-spacing-sm();

    @include bpk-rtl {
      margin-inline-end: unset;
    }

    @include bpk-breakpoint-mobile {
      margin-inline-end: 0;
    }
  }

  .cabin-class-travellers-wrapper {
    display: grid;
    grid-area: traveller;

    @include bpk-rtl {
      margin-inline-start: bpk-spacing-sm();
    }

    @include bpk-breakpoint-mobile {
      margin-top: bpk-spacing-md();

      @include bpk-rtl {
        margin-inline-start: unset;
      }
    }
  }

  .fsc-docked-first-above-mobile {
    @include bpk-breakpoint-above-mobile {
      @include bpk-input--docked-first-child;
    }
  }

  .fsc-docked-middle {
    @include bpk-input--docked-middle-child;
  }

  .fsc-docked-middle-above-tablet {
    @include bpk-breakpoint-above-tablet {
      @include bpk-input--docked-middle-child;
    }
  }

  .fsc-docked-last-above-tablet {
    @include bpk-breakpoint-above-tablet {
      @include bpk-input--docked-last-child;
    }
  }

  .fsc-docked-last-on-tablet {
    @include bpk-breakpoint-tablet-only {
      @include bpk-input--docked-last-child;
    }
  }

  // First instance of hotels checkbox; under date pickers
  // This will be rendered only if it is oneLine view
  // stylelint-disable-next-line no-duplicate-selectors
  .dates-wrapper {
    // stylelint-disable-next-line selector-max-compound-selectors
    .with-hotels-checkbox {
      display: none;

      @include bpk-breakpoint-above-tablet {
        display: inline-block;
      }
    }
  }

  // Second instance of hotels checkbox; under direct checkbox
  .with-hotels-wrapper {
    display: inline-block;
    width: 50%;
    padding-left: 1%; // Align with DateRangeSelector margin

    @include bpk-breakpoint-above-mobile {
      display: block;
      width: auto;
      padding-left: 0;
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    .with-hotels-checkbox {
      margin-top: 0;

      @include bpk-breakpoint-above-mobile {
        margin-bottom: 0;
      }
    }

    // Toggle visibility if it is oneLine view
    &.with-hotels-wrapper-oneline {
      // stylelint-disable-next-line selector-max-compound-selectors
      .with-hotels-checkbox {
        @include bpk-breakpoint-above-tablet {
          display: none;
        }
      }
    }
  }

  .direct-only-wrapper {
    grid-area: direct-flights;

    @include bpk-breakpoint-mobile {
      // stylelint-disable-next-line selector-max-compound-selectors
      span {
        @include bpk-body-default;
      }
    }
  }

  .direct-only-wrapper-withhotels {
    display: inline-block;
    width: 50%;

    @include bpk-breakpoint-above-mobile {
      display: block;
      width: auto;
    }
  }

  .alt-origin {
    grid-area: alt-origin;

    @include bpk-breakpoint-mobile {
      margin-top: -1 * bpk-spacing-md();

      // stylelint-disable-next-line selector-max-compound-selectors
      span {
        @include bpk-body-default;
      }
    }
  }

  .alt-dest {
    grid-area: alt-dest;

    @include bpk-breakpoint-mobile {
      margin-top: -1 * bpk-spacing-md();

      // stylelint-disable-next-line selector-max-compound-selectors
      span {
        @include bpk-body-default;
      }
    }
  }

  .submit {
    width: fit-content;
    grid-area: search;
    justify-self: flex-end;
    border-radius: $bpk-border-radius-md;
    margin-inline-start: bpk-spacing-sm();

    @include bpk-breakpoint-mobile {
      width: 100%;
      margin-top: bpk-spacing-md();
      margin-inline-start: 0;

      @include bpk-body-default;
    }
  }
}

.one-line {
  @include bpk-breakpoint-above-tablet {
    grid-template:
      'origin destination dates dates traveller search' 1fr
      'alt-origin alt-dest . . . . ' 0.2fr
      'direct-flights direct-flights direct-flights . . . ' 0.2fr
      / 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }
}
