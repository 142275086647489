// map-get should not be wrapped in quotes. Prettier doesn't like adding this inline.
/* stylelint-disable function-url-quotes */
/* stylelint-disable declaration-no-important */

@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.CabinClassTravellersModal {
  transition: none;
}

.CabinClassTravellers {
  display: flex;
  padding: 0 bpk-spacing-base();
  flex-direction: column;
  justify-content: center;
  border-radius: 0 $bpk-border-radius-md $bpk-border-radius-md 0;
  background-color: $bpk-canvas-day;

  @include bpk-rtl {
    border-radius: $bpk-border-radius-md 0 0 $bpk-border-radius-md;
  }

  @include bpk-breakpoint-mobile {
    min-height: 3rem;
    padding: bpk-spacing-md() bpk-spacing-base();
    border-radius: $bpk-border-radius-md;

    @include bpk-rtl {
      border-radius: $bpk-border-radius-md;
    }
  }

  .form-item {
    display: grid;
  }
}

.multi-city {
  height: 3.5rem;
  border-radius: $bpk-border-radius-md;
}

.CabinClassTravellersPopup {
  max-width: 368.64px; /* stylelint-disable-line unit-disallowed-list */
}

.CabinClassTravellersSelector {
  &__class-travellers-trigger {
    width: 100%;
    padding: 0;
    text-align: left;
    cursor: pointer;

    svg {
      fill: $bpk-text-secondary-day;
      margin-inline-end: bpk-spacing-md();

      @include bpk-breakpoint-above-mobile {
        display: none;
      }
    }
  }

  &__triggertext {
    display: flex;
    margin-top: $bpk-one-pixel-rem * 2;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include bpk-rtl {
      text-align: right;
    }
  }

  &__passengers-legal {
    padding-top: 1.5rem;
  }

  &__passengers-legal p {
    margin: 0;
    padding: 0 0 1.125rem;
    color: #817b8f; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    font-size: 0.75rem; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    line-height: 1.125rem; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  }

  &__traveller-label {
    padding: 0.75rem 0 0.375rem;
    font-size: 1rem; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  }

  &__nudger-wrapper {
    display: table-row;
  }

  &__nudger {
    display: table-cell;
  }

  &__nudgertext {
    display: table-cell;
    padding-inline-start: 0.75rem;
  }

  &__cabin-class-economy-only {
    padding: 0.75rem;
    border-radius: 0.375rem;
    background-color: #f3f2f5; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    font-size: 0.75rem; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    line-height: 1.125rem; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  }

  &__cabin-class-economy-only p {
    margin: 0;
  }
}
